import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FlexLayout, Checkbox, Label, Details, DetailsContent } from '@deity/falcon-ui';
import { T } from '@deity/falcon-i18n';
import { AddressDetails } from '@deity/falcon-ecommerce-uikit';
import AddressForm from '../components/AddressForm';
import ErrorList from '../components/ErrorList';
import SectionHeader from './CheckoutSectionHeader';
import AddressPicker from './AddressPicker';
import {Input, Button, ButtonTypes} from "benlux-ecommerce-ui";
import { MondialRelayPickupsQuery } from '../../../queries/MondialRelayPickupsQuery';
import { SetMondialRelayPickupMutation } from '../../../mutation/SetMondialRelayPickup';

const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
const mergeOpeningHours = (openingHoursByDay) => {
  const merged = []

  for (let dayIndex in openingHoursByDay) {
    const hours = openingHoursByDay[dayIndex] || 'Fermé'
    if (merged.length > 0) {
      const prev = merged[merged.length - 1];
      if (prev.hours === hours.trim()) {
        prev.end = dayIndex;
      } else {
        merged.push({
          start: dayIndex,
          end: dayIndex,
          hours: hours.trim()
        })
      }
    } else {
      merged.push({
        start: 0,
        end: 0,
        hours: hours.trim()
      })
    }
  }

  return merged;
}

const MondialRelayPickupSelector = () => {
  const [postalCodeTmp, setPostalCodeTmp] = useState("44150")
  const [postalCode, setPostalCode] = useState("44150")

  return (
    <>
      <form onSubmit={(e) => {
        e.preventDefault()
        setPostalCode(postalCodeTmp)
      }}>
        <input type="text" placeholder="Code postal" onChange={(e) => setPostalCodeTmp(e.target.value)} defaultValue={postalCode}/>
        <input type="submit" value="Rechercher"/>
      </form>
      <MondialRelayPickupsQuery variables={{postalCode, countryCode: 'FR'}}>
        {({loading, mondialRelayPickups}) => {
          if (loading) {
            return <span>Chargement...</span>
          }

          if (mondialRelayPickups) {
            return (
              <SetMondialRelayPickupMutation>
                {(setMondialRelayPickup, {loading, error}) => 
                  <ul>
                    {mondialRelayPickups.items.map((item, index) => {
                      const openingHours = mergeOpeningHours([
                        item.horaires_lundi,
                        item.horaires_mardi,
                        item.horaires_mercredi,
                        item.horaires_jeudi,
                        item.horaires_vendredi,
                        item.horaires_samedi,
                        item.horaires_dimanche
                      ])
                      return (
                        <li key={item.num}>
                          <div
                            style={{display: 'flex', margin: '10px 0', borderBottom: '1px solid #000', justifyContent: 'space-between'}}
                            onClick={() => setMondialRelayPickup({variables: {pickupId: item.num, countryCode: item.pays}})}
                          >
                            <ul>
                              <li><b>{index + 1} - {item.lgadr1}</b></li>
                              <li>{item.lgadr2}</li>
                              <li>{item.lgadr3}</li>
                              <li>{item.lgadr4}</li>
                              <li>{item.cp} - {item.ville}</li>
                              {/*<img src={`https://www.mondialrelay.com/ww2/img/dynamique/pr.aspx?id=${item.pays}${item.num}`}/>*/}
                            </ul>
                            <ul>
                              {openingHours.map(({start, end, hours}) =>
                                <li>
                                  <b>{start === end ? days[start] : days[start] + ' - ' + days[end]} :</b> {hours}
                                </li>
                              )}
                            </ul>
                          </div>
                      </li>
                      )
                    }
                      
                    )}
                  </ul>
                }
              </SetMondialRelayPickupMutation>
            )
          }

          return null          
        }}
      </MondialRelayPickupsQuery>
    </>
  )
}


class MondialRelayPickupSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useTheSame: !!props.useTheSame,
      selectedAddressId: null
    };
  }

  render() {
    const {
      id,
      open,
      title,
      labelUseTheSame,
      setUseTheSame,
      selectedAddress,
      onEditRequested,
      submitLabel,
      errors,
      countries,
      loading,
      customerData,
      availableAddresses,
      defaultSelected
    } = this.props;
    let header;
    let content;
    const { street = [], ...selectedAddressRest } = selectedAddress || {};
    const initialAddressValue = {
      email: '',
      firstname: '',
      lastname: '',
      street1: street[0] || '',
      street2: street.length > 1 ? street[1] : '',
      postcode: '',
      city: '',
      telephone: '',
      countryId: countries.length > 1 ? '' : countries[0].code,
      ...selectedAddressRest
    };

    if (!open && selectedAddress) {
      header = (
        <SectionHeader
          title={title}
          onActionClick={onEditRequested}
          editLabel="Edit"
          complete
          // summary={<AddressDetails {...selectedAddress} />}
        />
      );
    } else {
      header = <SectionHeader title={title} open={open} />;
    }

    let selectedAvailableAddress;
    // if available addresses are passed then we should display dropdown so the user can pick his saved address
    if (availableAddresses) {
      // compute address that should be selected in the dropdown
      if (this.state.selectedAddressId) {
        // if we have locally selected address id then use it
        selectedAvailableAddress = availableAddresses.find(item => item.id === this.state.selectedAddressId);
      } else if (selectedAddress && selectedAddress.id) {
        // if there's passed selected address then use it
        selectedAvailableAddress = availableAddresses.find(item => item.id === selectedAddress.id);
      } else if (defaultSelected) {
        // if default that should be selected is passed then use it
        selectedAvailableAddress = availableAddresses.find(item => item.id === defaultSelected.id);
      }
    }

    return (
      <Details open={open} css={{'summary:after': { display: 'none' } }}>
        {header}
        <div>
          <MondialRelayPickupSelector/>
          <ErrorList errors={errors} />
        </div>
      </Details>
    );
  }
}

MondialRelayPickupSection.propTypes = {
  // id of the form - used for generating unique ids for form fields inside
  id: PropTypes.string,
  // flag that indicates if the section is currently open
  open: PropTypes.bool,
  // title of the section
  title: PropTypes.string,
  // currently selected address
  selectedAddress: PropTypes.shape({}),
  // callback that sets the address
  setAddress: PropTypes.func,
  // callback that should be called when user requests edit of this particular section
  onEditRequested: PropTypes.func,
  // flag indicates if "use the same address" is selected - if so then address form is hidden
  useTheSame: PropTypes.bool,
  // callback that sets value for "use the same address" feature
  setUseTheSame: PropTypes.func,
  // label for "use the same address" feature
  labelUseTheSame: PropTypes.string,
  // label for submit button
  submitLabel: PropTypes.string,
  // list of available addresses to pick from - if not passed then address selection field won't be presented
  availableAddresses: PropTypes.arrayOf(PropTypes.shape({})),
  // default selected address - address that should be selected when address picker is shown
  defaultSelected: PropTypes.shape({}),
  // list of available countries
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      localName: PropTypes.string
    })
  ),
  // errors passed from outside that should be displayed for this section
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string
    })
  )
};

export default MondialRelayPickupSection;
