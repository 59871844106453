import React from "react";
import { withApollo } from "react-apollo";
import isEqual from "lodash.isequal";
import { SET_SHIPPING } from "@deity/falcon-ecommerce-uikit/dist/Checkout/CheckoutMutation";
import TagManager from "react-gtm-module";

import { PLACE_ORDER } from "../mutation/PlaceOrder";
import { UPDATE_ADYEN_PAYMENT_DETAILS } from "../mutation/UpdateAdyenPaymentDetails";
import { ESTIMATE_SHIPPING_METHODS } from "../mutation/EstimateShippingMethods";
import { SAVE_CART_EMAIL } from "../mutation/SaveCartEmail";
import { GET_CART } from "../queries/CartQuery";
import { saveKilibaCustomer } from "../utils/kiliba";
import { SUBSCRIBE_TO_NEWSLETTER } from "../mutation/NewsletterMutation";
import { ADD_TO_CART, REMOVE_CART_ITEM } from "../mutation/CartMutation";

class CheckoutLogicImpl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      values: props.initialValues || { billingSameAsShipping: false },
      errors: {},
      availablePaymentMethods: [],
      availableShippingMethods: []
    };
  }

  setPartialState(partial, callback) {
    this.setState(
      state => ({
        ...state,
        ...partial,
        values: {
          ...state.values,
          ...(partial.values || {})
        }
      }),
      callback
    );
  }

  setLoading(loading, callback) {
    this.setPartialState({ loading }, callback);
  }

  getShippingMethodData(shippingMethod) {
    let data = {
      shippingCarrierCode: shippingMethod.carrierCode,
      shippingMethodCode: shippingMethod.methodCode
    };

    if (shippingMethod.carrierCode === "mondialrelay") {
      data.mondialRelayPickup = shippingMethod.pickup;
    }

    return data;
  }

  setEmail = ({ email, subscribeToNewsletter }) =>
    this.setLoading(true, () => {
      try {
        this.props.client
          .mutate({
            mutation: SAVE_CART_EMAIL,
            variables: { input: { email } }
          })
          .then(resp => {
            if (resp.errors) {
              // TODO: handle error
            } else {
              const {
                setGuestEmail: { kilibaCustomerKey }
              } = resp.data;
              saveKilibaCustomer(kilibaCustomerKey, null);
            }
          });
      } catch (e) {
        console.error(e);
      }

      const { cart } = this.props.client.readQuery({ query: GET_CART });
      const welcomeGiftSku = "cadeau-de-bienvenue";
      const welcomeGiftItem = cart.items.find(
        item => item.sku === welcomeGiftSku
      );

      if (subscribeToNewsletter && !welcomeGiftItem) {
        try {
          this.props.client
            .mutate({
              mutation: SUBSCRIBE_TO_NEWSLETTER,
              variables: { input: { email } }
            })
            .then(resp => {
              this.props.client
                .mutate({
                  mutation: ADD_TO_CART,
                  variables: {
                    input: {
                      sku: "cadeau-de-bienvenue",
                      qty: 1
                    }
                  },
                  refetchQueries: ["Cart"],
                  awaitRefetchQueries: true
                })
                .then(resp => {
                  this.setPartialState({
                    loading: false,
                    values: {
                      email
                    }
                  });
                });
            });
        } catch (e) {
          console.error(e);
          this.setPartialState({
            loading: false,
            values: {
              email
            }
          });
        }
      } else {
        if (welcomeGiftItem) {
          this.props.client
            .mutate({
              mutation: REMOVE_CART_ITEM,
              variables: {
                input: {
                  itemId: welcomeGiftItem.itemId
                }
              },
              refetchQueries: ["Cart"],
              awaitRefetchQueries: true
            })
            .then(resp => {
              this.setPartialState({
                loading: false,
                values: {
                  email
                }
              });
            });
        } else {
          this.setPartialState({
            loading: false,
            values: {
              email
            }
          });
        }
      }

      try {
        TagManager.dataLayer({
          dataLayer: {
            event: "CheckoutEmail",
            email
          }
        });

        window.sendinblue.identify(email);

        const brevoData = {
          id: cart.id,
          data: {
            itemsQty: cart.itemsQty,
            products: cart.items.map(item => {
              return {
                url: item.urlKey,
                name: item.name,
                brand: item.manufacturer,
                shortDescription: item.shortDescription,
                thumbnailUrl: item.thumbnailUrl,
                optionLabel:
                  item.itemOptions.length > 0 ? item.itemOptions[0].value : ""
              };
            })
          }
        };

        window.sendinblue.track("cart_updated", {}, brevoData);
      } catch (e) {
        console.error(e);
      }
    });

  // the following setters first set loading to true, and then in the callback actual values is set
  // and loading flag gets reset to false, so the flow goes through whole proces (loading > set value > loaded)
  setBillingSameAsShipping = same =>
    this.setLoading(true, () =>
      this.setPartialState({
        loading: false,
        values: {
          billingSameAsShipping: same,
          billingAddress: same ? this.state.values.shippingAddress : null
        }
      })
    );

  setBillingAddress = billingAddress =>
    this.setLoading(true, () =>
      this.setPartialState({
        loading: false,
        values: { billingAddress }
      })
    );

  setPaymentMethod = (paymentMethod, additionalData) => {
    this.setLoading(true, () =>
      this.setPartialState({
        loading: false,
        values: { paymentMethod, paymentAdditionalData: additionalData }
      })
    );
  };

  setShippingAddress = shippingAddress => {
    this.setLoading(true, () => {
      this.props.client
        .mutate({
          mutation: ESTIMATE_SHIPPING_METHODS,
          variables: {
            input: {
              address: { ...shippingAddress, email: this.state.values.email }
            }
          }
        })
        .then(resp => {
          if (resp.errors) {
            this.setPartialState({
              loading: false,
              availableShippingMethods: null
            });
          } else {
            const { estimateShippingMethods } = resp.data;
            const values = { shippingAddress };
            // if available shipping methods has changed then remove selected shipping method
            if (
              !isEqual(
                estimateShippingMethods,
                this.state.availablePaymentMethods
              )
            ) {
              values.shippingMethod = null;
            }
            this.setPartialState({
              loading: false,
              errors: {},
              values,
              availableShippingMethods: estimateShippingMethods
            });
          }
        });
    });
  };

  setShippingMethod = shippingMethod => {
    this.setLoading(true, () => {
      this.props.client
        .mutate({
          mutation: SET_SHIPPING,
          // refetch cart because totals have changed once shipping has been selected
          refetchQueries: ["Cart"],
          awaitRefetchQueries: true,
          variables: {
            input: {
              shippingAddress: {
                ...this.state.values.shippingAddress,
                email: this.state.values.email
              }, //{countryId: 'FR'},
              ...this.getShippingMethodData(shippingMethod)
            }
          }
        })
        .then(resp => {
          if (resp.errors) {
            this.setPartialState({
              loading: false,
              errors: { shippingMethod: resp.errors },
              availablePaymentMethods: null
            });
          } else {
            const values = { shippingMethod };
            // if available payment methods has changed then remove selected payment method
            if (
              !isEqual(
                resp.data.setShipping.paymentMethods,
                this.state.availablePaymentMethods
              )
            ) {
              values.paymentMethod = null;
            }

            this.setPartialState({
              loading: false,
              errors: {},
              values,
              availablePaymentMethods: resp.data.setShipping.paymentMethods
            });
          }
        })
        .catch(error => {
          this.setPartialState({
            loading: false,
            errors: { shippingMethod: [error] }
          });
        });
    });
  };

  updateAdyenPaymentDetails = data => {
    this.setLoading(true, () => {
      this.props.client
        .mutate({
          mutation: UPDATE_ADYEN_PAYMENT_DETAILS,
          variables: {
            input: {
              payload: data
            }
          }
        })
        .then(resp => {
          this.setPartialState({
            loading: false,
            error: null,
            result: {
              data: resp.data.updateAdyenPaymentDetails.data
            }
          });
        });
    });
  };

  placeOrder = () => {
    const handleResponse = resp => {
      if (resp.errors) {
        this.setPartialState({
          loading: false,
          errors: {
            order: resp.errors
          }
        });
      } else {
        this.setPartialState({
          loading: false,
          error: null,
          result: resp.data.placeOrder
        });
      }
    };

    this.setLoading(true, () => {
      this.props.client
        .mutate({
          mutation: PLACE_ORDER,
          variables: {
            input: {
              email: this.state.values.email,
              billingAddress: {
                ...this.state.values.billingAddress,
                email: this.state.values.email
              },
              paymentMethod: {
                method: this.state.values.paymentMethod,
                additionalData: this.state.values.paymentAdditionalData
              }
            }
          }
        })
        // promise catches the errors which are not passed to update callback
        .then(handleResponse)
        .catch(error => handleResponse({ errors: [error] }));
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.children({
          loading: this.state.loading,
          values: this.state.values,
          errors: this.state.errors,
          result: this.state.result,
          availablePaymentMethods: this.state.availablePaymentMethods,
          availableShippingMethods: this.state.availableShippingMethods,
          setEmail: this.setEmail,
          setShippingAddress: this.setShippingAddress,
          setBillingAddress: this.setBillingAddress,
          setBillingSameAsShipping: this.setBillingSameAsShipping,
          setShippingMethod: this.setShippingMethod,
          setPaymentMethod: this.setPaymentMethod,
          placeOrder: this.placeOrder,
          updateAdyenPaymentDetails: this.updateAdyenPaymentDetails
        })}
      </React.Fragment>
    );
  }
}

export const CheckoutLogic = withApollo(CheckoutLogicImpl);
