import React from "react";
import PropTypes from "prop-types";
import { Details } from "@deity/falcon-ui";
import { I18n, T } from "@deity/falcon-i18n";
import loadable from "src/components/loadable";
import SectionHeader from "./CheckoutSectionHeader";
import { ButtonTypes, Button, formatPrice } from "benlux-ecommerce-ui";
// import { DonationWidget } from "../../OctobreRose";

// Loading "PaymentMethodItem" component via loadable package
// to avoid premature import of Payment frontend-plugins and their dependencies on SSR
const PaymentMethodItem = loadable(() =>
  import(
    /* webpackChunkName: "checkout/payment-item" */ "./components/PaymentMethodItem"
  )
);

class PaymentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPayment: null,
      additionalData: null,
      isReady: false
    };
  }

  onPaymentSelected = (selectedPayment, additionalData) =>
    this.setState({
      selectedPayment,
      additionalData
    });

  resetSelected = () =>
    this.setState({
      selectedPayment: null,
      additionalData: null
    });

  selectPaymentOption = paymentMethodCode => {
    this.setState({
      selectedPayment: paymentMethodCode,
      additionalData: null,
      isReady: false
    });
  };

  onPaymentDetailsReady = (paymentDetails, isReady = true) => {
    this.setState({ additionalData: paymentDetails, isReady });
    this.props.setPayment(this.state.selectedPayment, paymentDetails);
  };

  toggleAgreeTerms = () =>
    this.setState({ agreeTerms: !this.state.agreeTerms });

  render() {
    const {
      open,
      selectedPayment,
      onEditRequested,
      values,
      loading,
      availablePaymentMethods,
      cart,
      result
    } = this.props;
    const total = cart.totals.find(t => t.code === "grand_total");
    const { isReady } = this.state;
    let header;
    if (!open && selectedPayment) {
      header = (
        <I18n>
          {t => (
            <SectionHeader
              title={"Paiement"}
              onActionClick={onEditRequested}
              editLabel={"Modifier"}
              complete
              // summary={<Text>{selectedPayment.title}</Text>}
            />
          )}
        </I18n>
      );
    } else {
      header = (
        <I18n>{t => <SectionHeader title={"Paiement"} open={open} />}</I18n>
      );
    }

    return (
      <>
        <Details open={open} css={{ "summary:after": { display: "none" } }}>
          {header}
          {/*values.shippingAddress && values.shippingMethod && (
            <div style={card.recap}>
              {values.shippingMethod.carrierCode === 'clickandcollect' ? (
                <div style={{display: 'block'}}>
                  <div>Votre commande sera à retirer à l'adresse suivante :<br/>
                  BENLUX<br/>
                  174 rue de Rivoli<br/>
                  75001 Paris
                  </div>
                </div>
                ) : (
                  <div>Votre commande sera envoyée à :<br/>
                    {`${values.shippingAddress.firstname} ${values.shippingAddress.lastname}`}<br/>
                    {values.shippingAddress.street[0]}<br/>
                    {`${values.shippingAddress.postcode} ${values.shippingAddress.city}`}
                  </div>
                )}
            </div>
          )*/}
          {/* <DonationWidget /> */}
          {availablePaymentMethods.map((paymentMethod, index) => (
            <PaymentMethodItem
              key={index}
              code={paymentMethod.code}
              selectedOption={this.state.selectedPayment}
              config={paymentMethod.config}
              title={paymentMethod.title}
              selectOption={this.selectPaymentOption}
              result={
                this.state.selectedPayment === paymentMethod.code
                  ? result
                  : null
              }
              onPaymentDetailsReady={this.onPaymentDetailsReady}
              updateAdyenPaymentDetails={this.props.updateAdyenPaymentDetails}
            />
          ))}
          {isReady && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px"
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  color: "#555",
                  fontWeight: "bold",
                  marginRight: "48px"
                }}
              >
                En poursuivant ma commande, je reconnais avoir lu et accepté{" "}
                <a
                  style={{ color: "#555" }}
                  href="/conditions-generales-de-vente"
                  target="_blank"
                >
                  les conditions générales de vente
                </a>
                , ainsi que mon souhait de procéder au paiement de la commande
                avant toute livraison.
              </p>
              <Button
                as={"button"}
                onClick={this.props.placeOrder}
                type={ButtonTypes.Primary}
                text={
                  selectedPayment === "paypal_express"
                    ? "Continuer sur PayPal"
                    : `Payer ${formatPrice(total.value)} €`
                }
                loading={loading}
                disabled={!isReady}
              />
            </div>
          )}
        </Details>
      </>
    );
  }
}

PaymentSection.propTypes = {
  // flag that indicates if the section is currently open
  open: PropTypes.bool,
  // all available payment methods
  availablePaymentMethods: PropTypes.arrayOf(PropTypes.shape({})),
  // currently selected payment method
  selectedPayment: PropTypes.string,
  // callback that should be called when user requests edit of this particular section
  onEditRequested: PropTypes.func,
  placeOrder: PropTypes.func,
  // callback that sets selected payment method
  setPayment: PropTypes.func,
  // errors passed from outside that should be displayed for this section
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string
    })
  )
};

export default PaymentSection;
