import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { graphql } from "react-apollo";
import * as Yup from "yup";

import { Box, Text, Link, Details } from "@deity/falcon-ui";
import { I18n, T } from "@deity/falcon-i18n";
import { SignOutMutation, GET_CUSTOMER } from "@deity/falcon-ecommerce-uikit";

import { Button, ButtonTypes } from "benlux-ecommerce-ui";

import SectionHeader from "./CheckoutSectionHeader";
import { SignInForm } from "../../account/SignIn/SignInForm";
import { FormField } from "../../account/SignIn/FormField";

import newsletterIcon from "./newsletter.png";

const CustomerSelectorSchema = Yup.object().shape({
  email: Yup.string()
    .email("L'adresse e-mail n'est pas valide.")
    .required("L'adresse e-mail est obligatoire.")
});

const EmailForm = ({ email = "", setEmail, loading }) => (
  <Formik
    onSubmit={values =>
      setEmail({
        email: values.email.toLowerCase(),
        subscribeToNewsletter: values.subscribeToNewsletter
      })
    }
    initialValues={{ email, subscribeToNewsletter: false }}
    validateOnChange={false}
    validationSchema={CustomerSelectorSchema}
  >
    <Form noValidate>
      <Box gridArea="input">
        <FormField
          name="email"
          required
          autoComplete="email"
          displayedLabel={"Adresse e-mail"}
          placeholder={"mon.adresse@email.fr"}
          type="email"
        />
      </Box>
      <div
        style={{
          backgroundColor: "#FAFAFA",
          padding: "18px",
          marginTop: "24px",
          borderRadius: "4px",
          display: "flex"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: "24px"
          }}
        >
          <img src={newsletterIcon} alt="newsletter" />
        </div>
        <div>
          <h2 style={{ fontSize: "14px", margin: "0" }}>
            Inscrivez-vous à la newsletter Benlux et recevez un cadeau de
            bienvenue !
          </h2>
          <p
            style={{
              color: "#696969",
              fontSize: "14px",
              margin: "4px 0 12px 0"
            }}
          >
            Vous recevrez en avant première nos bons plans et nouveautés par
            e-mail.
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Field
              type="checkbox"
              id="subscribe-newsletter"
              name="subscribeToNewsletter"
              style={{ margin: "0 8px 0 0" }}
            />
            <label
              htmlFor="subscribe-newsletter"
              style={{ color: "#333", fontSize: "14px" }}
            >
              Je m'inscris à la newsletter Benlux
            </label>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "12px"
        }}
      >
        <Button
          as={"submit"}
          type={ButtonTypes.Secondary}
          text={"Continuer"}
          loading={loading}
        />
      </div>
    </Form>
  </Formik>
);

EmailForm.propTypes = {
  setEmail: PropTypes.func.isRequired,
  email: PropTypes.string
};

class EmailSection extends React.Component {
  constructor(props) {
    super(props);

    let email = props.email || "";

    if (props.data && props.data.customer) {
      ({ email } = props.data.customer);
      props.setEmail({ email, subscribeToNewsletter: false });
    }

    this.state = {
      email: props.email,
      getPrevProps: () => this.props,
      signInForm: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      data: { customer: prevCustomer }
    } = prevState.getPrevProps();
    const {
      data: { customer: nextCustomer }
    } = nextProps;

    const { email: prevCustomerEmail } = prevCustomer || {};
    const { email: nextCustomerEmail } = nextCustomer || {};

    if (prevCustomerEmail !== nextCustomerEmail) {
      // user has signed in or out so we have to trigger setEmail() with the new value
      nextProps.setEmail({
        email: nextCustomerEmail,
        subscribeToNewsletter: false
      });

      // if there's no email in nextProps then customer just signed out - in that case we trigger
      // edit process so wizard switches to correct section
      if (!nextCustomerEmail) {
        nextProps.onEditRequested();
      }

      return {
        ...prevState,
        email: nextCustomerEmail || ""
      };
    }

    if (nextProps.email && nextProps.email !== prevState.email) {
      return {
        ...prevState,
        email: nextProps.email
      };
    }

    return null;
  }

  render() {
    let header;
    const { open, data, onEditRequested, loading } = this.props;
    const isSignedIn = !!data.customer;

    if (!open) {
      header = (
        <SignOutMutation>
          {signOut => (
            <I18n>
              {t => (
                <SectionHeader
                  title={
                    isSignedIn
                      ? data.customer.firstname + " " + data.customer.lastname
                      : "Contact"
                  }
                  editLabel={t(
                    isSignedIn
                      ? "customerSelector.signOut"
                      : "customerSelector.edit"
                  )}
                  onActionClick={isSignedIn ? signOut : onEditRequested}
                  complete
                  // summary={<Text>{this.state.email}</Text>}
                />
              )}
            </I18n>
          )}
        </SignOutMutation>
      );
    } else {
      header = (
        <I18n>{t => <SectionHeader title={"Contact"} open={open} />}</I18n>
      );
    }
    const content = (
      <Box>
        <EmailForm
          email={this.state.email}
          setEmail={this.props.setEmail}
          loading={loading}
        />
        <Text css={{ fontSize: "14px" }}>
          <span>Vous possédez un compte ? </span>
          <Link
            mx="xs"
            css={{
              color: "#EF394F",
              fontWeight: "700",
              marginRight: 0,
              marginLeft: 0
            }}
            onClick={() => this.setState({ signInForm: true })}
          >
            Se connecter
          </Link>
        </Text>
      </Box>
    );

    return (
      <Details open={open} css={{ "summary:after": { display: "none" } }}>
        {header}
        {content ? <div>{content}</div> : null}
        {this.state.signInForm && (
          <div style={{ display: "grid" }}>
            <SignInForm id="sign-in-page" />
          </div>
        )}
      </Details>
    );
  }
}

EmailSection.propTypes = {
  // data form GET_CUSTOMER query
  data: PropTypes.shape({}),
  // currently selected email
  email: PropTypes.string,
  // callback that sets email
  setEmail: PropTypes.func.isRequired,
  // callback that should be called when user requests edit of this particular section
  onEditRequested: PropTypes.func,
  // flag that indicates if the section is currently open
  open: PropTypes.bool
};

EmailSection.defaultProps = {
  email: ""
};

export default graphql(GET_CUSTOMER)(EmailSection);
