import React from 'react';
import {Text} from '@deity/falcon-ui';
import {Formik} from "formik";
import {
  Form,
  RequestPasswordResetMutation
} from "@deity/falcon-ecommerce-uikit";
import {Button, ButtonTypes, ForgotPasswordPopin} from "benlux-ecommerce-ui";
import {FormField} from "./FormField";
import * as Yup from "yup";

export const ForgotPasswordTrigger = () => {

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email('L\'adresse e-mail n\'est pas valide.').required('Une adresse e-mail est obligatoire.'),
  });

  return (
    <ForgotPasswordPopin
      form={
        <RequestPasswordResetMutation>
          {(requestPasswordReset, {loading, error, called}) => {
            const submitSucceed = called && !loading && !error;
            return (
              <Formik
                initialValues={{email: ''}}
                validationSchema={SignInSchema}
                onSubmit={values => requestPasswordReset({variables: {input: {email: values.email}}})}
              >
                {({values, errors, touched}) => (
                  <Form id="forgot-password" i18nId="forgotPassword">
                    <FormField
                      name="email"
                      type="email"
                      autoComplete="email"
                      errorMessage={touched.email && errors.email && errors.email}
                      placeholder={"E-mail"}
                    />
                    <Button
                      type={ButtonTypes.Secondary}
                      as={'submit'}
                      text={'Réinitialiser le mot de passe'}
                      loading={loading}
                    />
                    {submitSucceed && (
                      <Text mt="md" fontSize="md">
                        Si un compte est associé à
                        l'adresse <b>{values.email}</b>,
                        vous recevrez un e-mail avec un lien vous permettant de modifier votre
                        mot de passe.
                      </Text>
                    )}
                  </Form>
                )}
              </Formik>
            );
          }}
        </RequestPasswordResetMutation>
      }
    />
  )
}
