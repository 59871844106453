import gql from "graphql-tag";
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_MONDIAL_RELAY_PICKUPS = gql`
  query MondialRelayPickups($postalCode: String!, $countryCode: String!) {
    mondialRelayPickups(postalCode: $postalCode, countryCode: $countryCode) {
      items {
        code
        num
        lgadr1
        lgadr2
        lgadr3
        lgadr4
        cp
        ville
        pays
        latitude
        longitude
        horaires_lundi
        horaires_mardi
        horaires_mercredi
        horaires_jeudi
        horaires_vendredi
        horaires_samedi
        horaires_dimanche
        url_photo
      }
    }
  }
`;

export class MondialRelayPickupsQuery extends Query {
  static defaultProps = {
    query: GET_MONDIAL_RELAY_PICKUPS,
    fetchPolicy: "no-cache"
  };
}
