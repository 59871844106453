import gql from "graphql-tag";
import {Mutation} from "react-apollo";

export const SET_MONDIAL_RELAY_PICKUP = gql`
  mutation SetMondialRelayPickup($pickupId: String!, $countryCode: String!) {
    setMondialRelayPickup(pickupId: $pickupId, countryCode: $countryCode)
  }
`;

export class SetMondialRelayPickupMutation extends Mutation {
  static defaultProps = {
    mutation: SET_MONDIAL_RELAY_PICKUP,
    refetchQueries: ['Cart']
  };
}
