import gql from "graphql-tag";
import {Mutation} from "react-apollo";

export const PLACE_ORDER = gql`
  mutation PlaceOrder($input: PlaceOrderInput!) {
    placeOrder(input: $input) {
      __typename
      ... on PlaceOrderSuccessfulResult {
        orderId
        orderRealId
      }
      ... on PlaceOrder3dSecureResult {
        orderId
        data
      }
      ... on PlaceOrderPayPalResult {
        redirectUrl
      }
    }
  }
`;

export class PlaceOrderMutation extends Mutation {
  static defaultProps = {
    mutation: PLACE_ORDER
  };
}
