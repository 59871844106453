import gql from "graphql-tag";
import {Mutation} from "react-apollo";

export const UPDATE_ADYEN_PAYMENT_DETAILS = gql`
  mutation UpdateAdyenPaymentDetails($input: AdyenPaymentDetailsInput!) {
    updateAdyenPaymentDetails(input: $input) {
      __typename
      ... on PlaceOrderSuccessfulResult {
        orderId
        orderRealId
      }
      ... on PlaceOrder3dSecureResult {
        orderId
        data
      }
    }
  }
`;

export class UpdateAdyenPaymentDetailsMutation extends Mutation {
  static defaultProps = {
    mutation: UPDATE_ADYEN_PAYMENT_DETAILS
  };
}
