import gql from "graphql-tag";
import { Mutation } from "react-apollo";

export const SAVE_CART_EMAIL = gql`
  mutation SetGuestEmail($input: SetGuestEmailInput!) {
    setGuestEmail(input: $input) {
      kilibaCustomerKey
    }
  }
`;

export class SaveCartEmailMutation extends Mutation {
  static defaultProps = {
    mutation: SAVE_CART_EMAIL
  };
}
