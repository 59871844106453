import React, { useState } from "react";
import Helmet from "react-helmet";

import { Link as RouterLink, Redirect } from "react-router-dom";
import { Box, H2, H4, Button, Divider } from "@deity/falcon-ui";
import { T, I18n } from "@deity/falcon-i18n";
import {
  CountriesQuery,
  CustomerQuery,
  GET_CUSTOMER_WITH_ADDRESSES,
  toGridTemplate,
  Loader
} from "@deity/falcon-ecommerce-uikit";
import { Test3dSecure } from "@deity/falcon-payment-plugin";
import ErrorList from "../components/ErrorList";
import CustomerSelector from "./CustomerSelector";
import ShippingMethodSection from "./ShippingMethodSection";
import PaymentMethodSection from "./PaymentMethodSection";
import AddressSection from "./AddressSection";
import MondialRelayPickupSection from "./MondialRelayPickupSection";
import {
  CheckoutCartSummary,
  Button as ButtonUI,
  BoxLayout,
  BoxCheckout
} from "benlux-ecommerce-ui";
import { CartQuery } from "../../../queries/CartQuery";
import { ApplyCouponMutation } from "../../../mutation/CartMutation";
import { CheckoutLogic } from "../../../components/CheckoutLogic";

const CHECKOUT_STEPS = {
  EMAIL: "EMAIL",
  SHIPPING_ADDRESS: "SHIPPING_ADDRESS",
  BILLING_ADDRESS: "BILLING_ADDRESS",
  SHIPPING: "SHIPPING",
  PAYMENT: "PAYMENT",
  CONFIRMATION: "CONFIRMATION"
};

const CheckoutArea = {
  checkout: "checkout",
  cart: "cart",
  divider: "divider"
};

const ClickAndCollectCarrierCode = "clickandcollect";

// helper that computes step that should be open based on values from CheckoutLogic
const computeStepFromValues = (values, errors) => {
  if (!values.email || errors.email) {
    return CHECKOUT_STEPS.EMAIL;
  }

  //   if ((values.shippingMethod.carrierCode !== ClickAndCollectCarrierCode && values.shippingMethod.carrierCode !== 'mondialrelay' && !values.shippingAddress) || errors.shippingAddress) {
  if (!values.shippingAddress || errors.shippingAddress) {
    return CHECKOUT_STEPS.SHIPPING_ADDRESS;
  }

  if (!values.shippingMethod || errors.shippingMethod) {
    return CHECKOUT_STEPS.SHIPPING;
  }

  if (!values.billingAddress || errors.billingAddress) {
    return CHECKOUT_STEPS.BILLING_ADDRESS;
  }

  if (!values.paymentMethod || errors.paymentMethod) {
    return CHECKOUT_STEPS.PAYMENT;
  }

  return CHECKOUT_STEPS.CONFIRMATION;
};

class CheckoutWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: CHECKOUT_STEPS.EMAIL,
      getCurrentProps: () => this.props // eslint-disable-line react/no-unused-state
    };
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    const { checkoutData: currentPropsData } = currentState.getCurrentProps();
    const currentStepFromProps = computeStepFromValues(
      currentPropsData.values,
      currentPropsData.errors
    );
    const nextStepFromProps = computeStepFromValues(
      nextProps.checkoutData.values,
      nextProps.checkoutData.errors
    );

    const changedStep = { currentStep: nextStepFromProps };

    // if there's no step set yet then set it correctly
    if (!currentState.currentStep) {
      return changedStep;
    }

    // if loading has finished (changed from true to false) and there's no error then enforce current step
    // to value computed from the next props - this ensures that if user requested edit of particular step
    // then and it has been processed then we want to display step based on actual values from CheckoutLogic
    if (
      currentPropsData.loading &&
      !nextProps.checkoutData.loading &&
      !nextProps.checkoutData.error
    ) {
      return changedStep;
    }

    // if step computed from props has changed then use it as new step
    if (nextStepFromProps !== currentStepFromProps) {
      return changedStep;
    }

    return null;
  }

  setCurrentStep = currentStep => this.setState({ currentStep });

  render() {
    const { currentStep } = this.state;
    const {
      values,
      loading,
      errors,
      result,
      availableShippingMethods,
      availablePaymentMethods,
      setEmail,
      setShippingAddress,
      setBillingAddress,
      setBillingSameAsShipping,
      setShippingMethod,
      setPaymentMethod,
      placeOrder,
      updateAdyenPaymentDetails
    } = this.props.checkoutData;
    const { customerData } = this.props;
    let addresses;
    let defaultShippingAddress;
    let defaultBillingAddress;
    let orderResult = null;
    let selectedCarrierCode = values.shippingMethod
      ? values.shippingMethod.carrierCode
      : null;

    if (!loading && result) {
      if (!result.data) {
        // Order is placed
        orderResult = <Redirect to="/confirmation-commande" />;
      }
    }

    // detect if user is logged in - if so and he has address list then use it for address sections
    if (
      customerData &&
      customerData.addresses &&
      customerData.addresses.length
    ) {
      ({ addresses } = customerData);
      defaultShippingAddress = addresses.find(item => item.defaultShipping);
      defaultBillingAddress = addresses.find(item => item.defaultBilling);
    }

    return (
      <ApplyCouponMutation>
        {(applyCoupon, { loading, error }) => (
          <CountriesQuery>
            {({ countries }) => (
              <CartQuery>
                {({ cart }) => {
                  // cart is empty and it's not a "placeOrder" result so redirect user to the homepage
                  if (!loading && !orderResult && cart.itemsQty === 0) {
                    return <Redirect to={"/"} />;
                  } else if (orderResult) {
                    return orderResult;
                  }

                  return (
                    <>
                      <Helmet>
                        <title>Commandez</title>
                        <meta name="page-loaded" content="true" />
                      </Helmet>
                      <BoxLayout>
                        <Box gridArea={CheckoutArea.cart}>
                          <CheckoutCartSummary
                            errorCoupon={error}
                            products={cart}
                            onApplyCouponCode={code =>
                              applyCoupon({
                                variables: { input: { couponCode: code } }
                              })
                            }
                            loadingCouponCode={loading}
                            shippingMethod={values.shippingMethod}
                            placeOrderAllowed={
                              currentStep === CHECKOUT_STEPS.CONFIRMATION
                            }
                            loading={this.props.checkoutData.loading}
                            onPlaceOrder={placeOrder}
                          />
                        </Box>
                        <I18n>
                          {t => (
                            <BoxCheckout>
                              {loading && (
                                <Loader
                                  variant="overlay"
                                  style={{
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0
                                  }}
                                />
                              )}
                              <CustomerSelector
                                open={currentStep === CHECKOUT_STEPS.EMAIL}
                                onEditRequested={() =>
                                  this.setCurrentStep(CHECKOUT_STEPS.EMAIL)
                                }
                                email={values.email}
                                setEmail={setEmail}
                                loading={this.props.checkoutData.loading}
                              />
                              {true && ( //!values.shippingMethod || !values.shippingMethod.carrierCode || ['clickandcollect', 'mondialrelay'].indexOf(values.shippingMethod.carrierCode) === -1 && (
                                <>
                                  <Divider my="md" />
                                  <AddressSection
                                    id="shipping-address"
                                    open={
                                      currentStep ===
                                      CHECKOUT_STEPS.SHIPPING_ADDRESS
                                    }
                                    countries={countries.items}
                                    onEditRequested={() =>
                                      this.setCurrentStep(
                                        CHECKOUT_STEPS.SHIPPING_ADDRESS
                                      )
                                    }
                                    title={"Adresse de livraison"}
                                    submitLabel={t("checkout.nextStep")}
                                    selectedAddress={values.shippingAddress}
                                    setAddress={setShippingAddress}
                                    errors={errors.shippingAddress}
                                    availableAddresses={addresses}
                                    defaultSelected={defaultShippingAddress}
                                    customerData={customerData}
                                    loading={this.props.checkoutData.loading}
                                  />
                                </>
                              )}
                              <Divider my="md" />
                              <ShippingMethodSection
                                open={currentStep === CHECKOUT_STEPS.SHIPPING}
                                onEditRequested={() =>
                                  this.setCurrentStep(CHECKOUT_STEPS.SHIPPING)
                                }
                                shippingAddress={values.shippingAddress}
                                selectedShipping={values.shippingMethod}
                                setShippingAddress={setShippingAddress}
                                availableShippingMethods={
                                  availableShippingMethods
                                }
                                setShipping={setShippingMethod}
                                errors={errors.shippingMethod}
                                loading={this.props.checkoutData.loading}
                              />
                              <Divider my="md" />
                              <AddressSection
                                id="billing-address"
                                open={
                                  currentStep === CHECKOUT_STEPS.BILLING_ADDRESS
                                }
                                onEditRequested={() =>
                                  this.setCurrentStep(
                                    CHECKOUT_STEPS.BILLING_ADDRESS
                                  )
                                }
                                title={"Adresse de facturation"}
                                submitLabel={t("checkout.nextStep")}
                                countries={countries.items}
                                selectedAddress={values.billingAddress}
                                setAddress={setBillingAddress}
                                setUseTheSame={setBillingSameAsShipping}
                                useTheSame={values.billingSameAsShipping}
                                labelUseTheSame={
                                  "Utiliser l'adresse de livraison"
                                }
                                availableAddresses={addresses}
                                defaultSelected={defaultBillingAddress}
                                customerData={customerData}
                                loading={this.props.checkoutData.loading}
                              />
                              <Divider my="md" />
                              <PaymentMethodSection
                                open={
                                  currentStep === CHECKOUT_STEPS.PAYMENT ||
                                  currentStep === CHECKOUT_STEPS.CONFIRMATION
                                }
                                onEditRequested={() =>
                                  this.setCurrentStep(CHECKOUT_STEPS.PAYMENT)
                                }
                                selectedPayment={values.paymentMethod}
                                cart={cart}
                                availablePaymentMethods={
                                  availablePaymentMethods
                                }
                                setPayment={setPaymentMethod}
                                errors={errors.paymentMethod}
                                values={values}
                                result={result}
                                updateAdyenPaymentDetails={
                                  updateAdyenPaymentDetails
                                }
                                loading={this.props.checkoutData.loading}
                                placeOrder={placeOrder}
                              />
                              <Divider my="md" />
                              <ErrorList errors={errors.order} />
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "#b7b7b7",
                                  marginTop: "60px",
                                  textAlign: "center"
                                }}
                              >
                                Le site benlux.fr est proposé par BENLUX - 174
                                rue de Rivoli, 75001 Paris - RCS Paris B 316 696
                                038
                              </p>
                            </BoxCheckout>
                          )}
                        </I18n>
                        {orderResult}
                      </BoxLayout>
                      {/*</Box>*/}
                    </>
                  );
                }}
              </CartQuery>
            )}
          </CountriesQuery>
        )}
      </ApplyCouponMutation>
    );
  }
}

const CheckoutPage = () => (
  <CheckoutLogic>
    {checkoutData => (
      <CustomerQuery query={GET_CUSTOMER_WITH_ADDRESSES} loader={true}>
        {({ customer }) => (
          <CheckoutWizard checkoutData={checkoutData} customerData={customer} />
        )}
      </CustomerQuery>
    )}
  </CheckoutLogic>
);

export default CheckoutPage;
