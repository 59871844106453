import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Label,
  FlexLayout,
  Details,
  DetailsContent,
  Text,
  Radio,
  Box
} from "@deity/falcon-ui";
import { I18n, T } from "@deity/falcon-i18n";
import ErrorList from "../components/ErrorList";
import SectionHeader from "./CheckoutSectionHeader";
import {
  ShippingMethodOption,
  Input,
  Button,
  ButtonTypes
} from "benlux-ecommerce-ui";
import { MondialRelayPickupsQuery } from "../../../queries/MondialRelayPickupsQuery";

import "./ShippingMethodSection.css";

const days = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
const mergeOpeningHours = openingHoursByDay => {
  const merged = [];

  for (let dayIndex in openingHoursByDay) {
    const hours = openingHoursByDay[dayIndex] || "Fermé";
    if (merged.length > 0) {
      const prev = merged[merged.length - 1];
      if (prev.hours === hours.trim()) {
        prev.end = dayIndex;
      } else {
        merged.push({
          start: dayIndex,
          end: dayIndex,
          hours: hours.trim()
        });
      }
    } else {
      merged.push({
        start: 0,
        end: 0,
        hours: hours.trim()
      });
    }
  }

  return merged;
};

const MondialRelayPickupMap = ({
  pickups,
  selectedIndex,
  setSelectedIndex
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [markers, setMarkers] = useState([]);
  const map = useRef(null);

  const scriptAlreadyExists = () =>
    document.querySelector("script#leaflet-js") !== null;

  const appendLeafletCSS = () => {
    const css = document.createElement("link");
    css.id = "leaflet-css";
    css.href = "https://unpkg.com/leaflet@1.9.4/dist/leaflet.css";
    css.rel = "stylesheet";
    document.head.append(css);
  };

  const appendLeafletScript = () => {
    const script = document.createElement("script");
    script.id = "leaflet-js";
    script.src = "https://unpkg.com/leaflet@1.9.4/dist/leaflet.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.onload = initMap;
    document.body.append(script);
  };

  const initMap = () => {
    setTimeout(() => {
      map.current = window.L.map("mr-leaflet-map").setView(
        [48.8629715, 2.332471, 17],
        16
      );
      window.L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(map.current);
      addPickupsToMap();
    }, 100);
  };

  useEffect(() => {
    if (!scriptAlreadyExists()) {
      appendLeafletScript();
      appendLeafletCSS();
    } else {
      initMap();
    }
  }, []);

  const selectMarker = marker => {
    marker.openPopup();
    var px = map.current.project(marker.getPopup().getLatLng()); // find the pixel location on the map where the popup anchor is
    px.y -= marker.getPopup()._container.clientHeight / 2; // find the height of the popup container, divide by 2, subtract from the Y axis of marker location
    map.current.panTo(map.current.unproject(px), { animate: true });
  };

  const addPickupsToMap = () => {
    if (pickups.length > 0) {
      let markers = [];
      pickups.forEach((pickup, index) => {
        const popup = `<p>${
          pickup.lgadr1
        }</p><img width="200" src="${`https://www.mondialrelay.com/ww2/img/dynamique/pr.aspx?id=${pickup.pays}${pickup.num}`}"/>`;
        const marker = window.L.marker([pickup.latitude, pickup.longitude])
          .bindPopup(popup)
          .addTo(map.current)
          .on("click", () => setSelectedIndex(index));
        markers.push(marker);
        if (index === 0) {
          selectMarker(marker);
        }
      });
      setMarkers(markers);
    }
  };

  useEffect(() => {
    if (markers.length > 0) {
      selectMarker(markers[selectedIndex]);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (map.current) {
      addPickupsToMap();
    }
  }, [pickups]);

  return <div style={{ flex: 1 }} id="mr-leaflet-map" />;
};

const MondialRelayPickupModal = ({ onPickupChange, shippingAddress }) => {
  const [open, setOpen] = useState(true);
  const [selectedPickup, setSelectedPickup] = useState(null);

  useEffect(() => {
    //document.getElementsByTagName('html')[0].style.overflow = (open ? 'hidden' : 'initial')
  }, [open]);

  const onSelectPickup = pickup => {
    setSelectedPickup(pickup);
    onPickupChange(pickup);
    setOpen(false);
  };

  return (
    <>
      <div
        style={{
          padding: "14px 20px",
          background: "#FAFAFA",
          borderRadius: "3px",
          margin: "-10px 0 16px",
          border: "1px solid #CCC",
          fontSize: "14px"
        }}
      >
        {selectedPickup && (
          <p style={{ lineHeight: "20px", margin: "0 0 14px" }}>
            <b>{selectedPickup.lgadr1}</b>
            <br />
            {selectedPickup.lgadr2}
            {selectedPickup.lgadr2 ? <br /> : null}
            {selectedPickup.lgadr3}
            {selectedPickup.lgadr3 ? <br /> : null}
            {selectedPickup.lgadr4}
            {selectedPickup.lgadr4 ? <br /> : null}
            {selectedPickup.cp} - {selectedPickup.ville}
          </p>
        )}
        <Button
          type={ButtonTypes.Primary}
          onClick={() => setOpen(true)}
          text={
            selectedPickup
              ? "Changer de Point Relais®"
              : "Sélectionner un Point Relais®"
          }
        />
      </div>
      {open && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999999999,
            display: open ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              position: "relative",
              maxWidth: "98%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div
              style={{
                background: "white",
                width: "1050px",
                maxWidth: "100%",
                height: "fit-content",
                maxHeight: "88vh",
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                overflow: "hidden",
                position: "relative"
              }}
            >
              {/* Close button */}
              <div
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                  cursor: "pointer"
                }}
                onClick={() => setOpen(false)}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18 6L6 18"
                    stroke="#333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <MondialRelayPickupSelector
                initialPostalCode={shippingAddress.postcode}
                countryCode={shippingAddress.countryId}
                onSelectPickup={onSelectPickup}
                selectedPickup={selectedPickup}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const MondialRelayPickupSelector = ({
  onSelectPickup,
  selectedPickup,
  initialPostalCode,
  countryCode
}) => {
  const [postalCodeTmp, setPostalCodeTmp] = useState(
    selectedPickup ? selectedPickup.cp : initialPostalCode
  );
  const [postalCode, setPostalCode] = useState(
    selectedPickup ? selectedPickup.cp : initialPostalCode
  );
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCode);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const listRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (listRef.current) {
        // Scroll to selected pickup
        const list = listRef.current;
        const item = list.children[selectedIndex];
        if (item) {
          list.scroll({
            top: item.offsetTop,
            behavior: "smooth"
          });
        }
      }
    }, 100);
  }, [selectedIndex]);

  return (
    <MondialRelayPickupsQuery
      variables={{ postalCode, countryCode: selectedCountryCode }}
    >
      {({ loading, mondialRelayPickups }) => (
        <>
          <div style={{ borderBottom: "3px solid #EEE", padding: "12px 24px" }}>
            <form
              onSubmit={e => {
                e.preventDefault();
                setPostalCode(postalCodeTmp);
                setSelectedIndex(0);
              }}
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Input
                type="text"
                placeholder="Code postal"
                onChange={e => setPostalCodeTmp(e.target.value)}
                defaultValue={postalCode}
                style={{ width: "150px", height: "46px", marginRight: "12px" }}
              />
              <Button
                as="submit"
                text="Rechercher"
                loading={loading}
                type={ButtonTypes.Secondary}
              />
            </form>
          </div>
          <div
            style={{ display: "flex", overflow: "hidden" }}
            id="mr-pickup-selector"
          >
            <ul
              style={{
                overflow: "auto",
                width: "40%",
                borderRight: "3px solid #EEE",
                margin: 0,
                position: "relative"
              }}
              ref={listRef}
              id="mr-pickup-list"
            >
              {mondialRelayPickups &&
                mondialRelayPickups.items &&
                mondialRelayPickups.items.map((item, index) => {
                  const openingHours = mergeOpeningHours([
                    item.horaires_lundi,
                    item.horaires_mardi,
                    item.horaires_mercredi,
                    item.horaires_jeudi,
                    item.horaires_vendredi,
                    item.horaires_samedi,
                    item.horaires_dimanche
                  ]);
                  return (
                    <li key={item.num}>
                      <div
                        style={{
                          padding: "12px 24px",
                          borderBottom: "3px solid #EEE",
                          fontSize: "14px"
                        }}
                        //onClick={() => setMondialRelayPickup({variables: {pickupId: item.num, countryCode: item.pays}})}
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedIndex(index);
                        }}
                      >
                        <p style={{ lineHeight: "20px" }}>
                          <b>
                            {index + 1} - {item.lgadr1}
                          </b>
                          <br />
                          {item.lgadr2}
                          {item.lgadr2 ? <br /> : null}
                          {item.lgadr3}
                          {item.lgadr3 ? <br /> : null}
                          {item.lgadr4}
                          {item.lgadr4 ? <br /> : null}
                          {item.cp} - {item.ville}
                        </p>
                        <p style={{ lineHeight: "20px", fontSize: "13px" }}>
                          {openingHours.map(({ start, end, hours }) => (
                            <>
                              <b>
                                {start === end
                                  ? days[start]
                                  : days[start] + " - " + days[end]}{" "}
                                :
                              </b>{" "}
                              {hours}
                              <br />
                            </>
                          ))}
                        </p>
                        {selectedIndex === index && (
                          <Button
                            text="Sélectionner ce Point Relais®"
                            type={ButtonTypes.Primary}
                            onClick={() => onSelectPickup(item)}
                          />
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
            <MondialRelayPickupMap
              pickups={
                mondialRelayPickups && mondialRelayPickups.items
                  ? mondialRelayPickups.items
                  : []
              }
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
          </div>
        </>
      )}
    </MondialRelayPickupsQuery>
  );
};

const ShippingSelector = ({
  availableShippingOptions = [],
  onShippingSelected,
  selectedShipping,
  shippingAddress
}) => (
  <Box my="md">
    {availableShippingOptions.map(option => (
      <ShippingMethodOption
        key={option.carrierCode}
        value={option.carrierCode}
        name="shipping"
        type={"radio"}
        onChange={() => onShippingSelected(option)}
        item={option}
        children={
          option.carrierCode === "mondialrelay" &&
          selectedShipping &&
          selectedShipping.carrierCode === "mondialrelay" ? (
            <MondialRelayPickupModal
              shippingAddress={shippingAddress}
              onPickupChange={pickup =>
                onShippingSelected({ ...option, pickup: pickup.num })
              }
            />
          ) : null
        }
      />
    ))}
  </Box>
);

ShippingSelector.propTypes = {
  availableShippingOptions: PropTypes.arrayOf(PropTypes.shape({})),
  onShippingSelected: PropTypes.func
};

class ShippingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedShipping: null
    };
  }

  onShippingSelected = selectedShipping => {
    this.setState({ selectedShipping });
    //this.props.setShipping(selectedShipping);
  };

  render() {
    const {
      open,
      onEditRequested,
      availableShippingMethods,
      selectedShipping,
      errors,
      loading
    } = this.props;
    let header;
    if (!open && selectedShipping) {
      header = (
        <I18n>
          {t => (
            <SectionHeader
              title={"Mode de livraison"}
              onActionClick={onEditRequested}
              editLabel={t("edit")}
              complete
              // summary={<Text>{selectedShipping.carrierTitle}</Text>}
            />
          )}
        </I18n>
      );
    } else {
      header = (
        <I18n>
          {t => <SectionHeader title={"Mode de livraison"} open={open} />}
        </I18n>
      );
    }

    return (
      <Details open={open} css={{ "summary:after": { display: "none" } }}>
        {header}
        {/* <div
          style={{
            borderRadius: "3px",
            border: "solid 1px #EF394F",
            padding: "12px 24px",
            marginTop: "24px",
            marginBottom: "12px",
            fontSize: "14px",
            color: "#EF394F",
            background: "#ef394f1a",
            fontWeight: "500"
          }}
        >
          En raison des Jeux Olympiques de Paris 2024, nos délais de livraison
          peuvent être un peu rallongés. Toute l'équipe BENLUX vous remercie
          d'avance pour votre compréhension.
        </div> */}
        {/*
        <div style={{borderRadius: '3px', border: 'solid 1px #008500', padding: '12px 24px', marginTop: '24px', marginBottom: '12px', fontSize: '14px', color: '#008500', background: '#0085001a', fontWeight: '500'}}>
          Commandez avant le 20 décembre à minuit et recevez votre colis avant Noël
        </div>
        */}
        <div>
          {availableShippingMethods.length === 0 ? (
            <Text color="error" mb="sm">
              <T id="checkout.noShippingMethodsAvailable" />
            </Text>
          ) : (
            <ShippingSelector
              availableShippingOptions={availableShippingMethods}
              onShippingSelected={this.onShippingSelected}
              selectedShipping={this.state.selectedShipping}
              shippingAddress={this.props.shippingAddress}
            />
          )}
          <ErrorList errors={errors} />
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
        >
          <Button
            as={"button"}
            onClick={() => this.props.setShipping(this.state.selectedShipping)}
            type={"secondary"}
            text={"Continuer"}
            loading={loading}
            disabled={
              !this.state.selectedShipping ||
              (this.state.selectedShipping &&
                this.state.selectedShipping.carrierCode === "mondialrelay" &&
                !this.state.selectedShipping.pickup)
            }
          />
        </div>
      </Details>
    );
  }
}

ShippingSection.propTypes = {
  // flag that indicates if the section is currently open
  open: PropTypes.bool,
  // all available shipping methods
  availableShippingMethods: PropTypes.arrayOf(PropTypes.shape({})),
  // callback that should be called when user requests edit of this particular section
  onEditRequested: PropTypes.func,
  // currently selected shipping method
  selectedShipping: PropTypes.shape({}),
  // callback that sets selected shipping method
  setShipping: PropTypes.func,
  // errors passed from outside that should be displayed for this section
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string
    })
  )
};

export default ShippingSection;
