import React from "react";
import { Formik } from "formik";
import {
  Form,
  FormErrorSummary,
  SignInMutation
} from "@deity/falcon-ecommerce-uikit";
import "./SignIn.css";
import { Button, ButtonTypes } from "benlux-ecommerce-ui";
import { FormField, FormFieldPassword } from "./FormField";
import { ForgotPasswordTrigger } from "./ForgotPasswordTrigger";
import * as Yup from "yup";

export const SignInForm = ({ onCompleted, id }) => {
  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email("L'adresse e-mail n'est pas valide.")
      .required("Une adresse e-mail est obligatoire."),
    password: Yup.string().required("Un mot de passe est obligatoire.")
  });

  return (
    <SignInMutation>
      {(signIn, { loading, error }) => {
        return (
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validateOnChange={false}
            validationSchema={SignInSchema}
            onSubmit={values =>
              signIn({ variables: { input: values } }).then(
                () => onCompleted && onCompleted()
              )
            }
          >
            {({ errors, touched }) => (
              <Form id={id} i18nId="signIn">
                {error && (
                  <FormErrorSummary
                    errors={error.graphQLErrors.map(e => e.message)}
                  />
                )}
                <FormField
                  name="email"
                  type="email"
                  errorMessage={touched.email && errors.email && errors.email}
                  autoComplete="email"
                  placeholder={"E-mail"}
                />
                <FormFieldPassword
                  name="password"
                  validate={[]}
                  type="password"
                  autoComplete="new-password"
                  errorMessage={
                    touched.password && errors.password && errors.password
                  }
                  placeholder={"Mot de passe"}
                  passwordValidator={false}
                />
                <div className={"rightObject"}>
                  <ForgotPasswordTrigger />
                </div>
                <div className={"rightObject"}>
                  <Button
                    type={ButtonTypes.Secondary}
                    as={"submit"}
                    text={"Se connecter"}
                    loading={loading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        );
      }}
    </SignInMutation>
  );
};
